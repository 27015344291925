import React from 'react';

const Footer = () => {


    return (
        <p>&copy; Copyright 2023, Alejandro Griffith</p>
    );
};

export default Footer;
